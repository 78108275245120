.bodyContainer{
    padding: 5px 100px 5px 100px;
}

.photoContentContainerRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.imageContainer {
    height: 325px;
    width: 350px;
    background-color: grey;
}
.mainImage {
    width: 100%;
    height: 100%;
}
.mainContentContainer {
    margin-left: 10px;
    height: 325px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.featuresList {
    color: rgb(98, 98, 98)
}
.bulletRow {
    margin-top: 8px;
    font-size: 1.2rem;
}


.servicesContainer {
    margin-top: 6px;
    background-color:  rgb(52, 100, 183);
    min-height: 250px;

}

.centeredContainer {
    max-width: 1750px;
}
.servicesBox {
    padding-top: 5px;
    margin: 10px 20px 0 20px;
    width: 400px;
    display: inline-block;
}
ul.servicesList { list-style:none;  margin-top: 0px; margin-bottom: 0px;}
ul.servicesList li:before {
    content: "\27A4";
}
.servicesItem {
    color:white;
    margin: 5px;
    font-weight: bold;
    font-size: 1.1rem;
}

@media  (max-width: 750px ){
    .bodyContainer{
        padding: 5px 5px 5px 5px;
    }
    .photoContentContainerRow {
       align-items: center;
        flex-direction: column;
    }
    .mainContentContainer {height: 250px; width: 320px}
    .centeredContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .servicesItem {
        font-size: .9rem;
    }
}