.headerContainer {
    background-color: rgb(52, 100, 183);
    padding: 15px 200px;
    
}

.headingRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.logoName {
    border: 1px solid white;
    padding: 5px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}
.callNumber {
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
}

@media  (max-width: 1050px ){
    .headerContainer {
        padding: 15px 50px;
    }
}

@media  (max-width: 675px ){
    .logoName {
        font-size: 1rem;

    }
}

@media  (max-width: 675px ){
    .logoName {
        font-size: .8rem;
    }
    .callNumber {
        font-size: .8rem
    }
}

@media  (max-width: 450px ){
    .logoName {
        font-size: .6rem;
    }
    .callNumber {
        font-size: .6rem
    }
}