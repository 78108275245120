.reviewsHeading {
    width: 100px;
    margin: 0 auto;
    margin-top: 15px;
    font-size: 1.2rem;
    color: rgb(155, 25, 25);
    font-weight: bold;
}
.reviewContainer {
    margin: 5px 0px;
    overflow-x: auto;
   white-space: nowrap;
   max-width: 1300px;
   margin: 0 auto;

}

.reviewBox {
    height: 300px;
    width: 350px;
    background-color: rgb(231, 230, 230);
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align:middle;
}

.reveiwContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.starsContainer {
    width:80px;
    margin: 0 auto;
}
.subjectRow {color: black; margin-top: 5px;}

.descriptionRow {
    width: 90%;
    white-space: normal;
    margin: 5px 0;
    color: rgb(98, 98, 98);
    font-style: italic;
}

.reviewerName {color: rgb(0, 110, 255)}

@media  (max-width: 510px ){
    .reviewBox {
        height: 250px;
        width: 250px
    }
}